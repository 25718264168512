<template lang="pug">
	.table-show-more.d-flex.justify-content-center.align-items-center(v-if='totalPages > currentPage')
		a.b3.text-primary(href='javascript:void(0)' @click='nextPage') {{ $t('table.showMore') }}
</template>

<script>
	export default {
		name 	: 'DTableShowMore',
		props 	: {
			hidePagination 	: false,
			perPage 		: {
				type 	: Number,
				default : 10
			},
			currentPage 	: {
				type 	: Number,
				default : null
			},
			totalPages		: {
				type 	: Number,
				default : 0
			},
			totalItems 		: {
				type 	: [String, Number],
				default : 0
			}
		},
		computed : {
			pageIndex : {
				get () { return this.currentPage },
				set (page) { this.$emit("changepage", page); }
			}
		},
		methods : {
			nextPage () {
				this.$emit("changepage", this.currentPage+1);
			}
		}
	}
</script>
